@import '../../../assets/variables';

.MainSection {
  &Container {
    scroll-snap-align: center;
  }

  &Position,
  &Container {
    height: 100vh;

    @include landscape {
      height: unset;
      min-height: 889px;
    }

    @include phone {
      height: unset;
      min-height: 737px;
    }
  }

  &Position {
    position: relative;
    overflow: hidden;

    @include tablet {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &Title {
    margin-left: auto;
    margin-right: auto;
    max-width: 793px;

    @include landscape {
      margin-bottom: 32px;
    }

    @media (max-width: 792px) {
      max-width: 600px;

      > span:before {
        transform: translate(-143%, -2%) !important;
      }
    }

    @media (max-width: 750px) {
      max-width: 600px;

      > span:before {
        transform: translate(-128%, -2%) !important;
      }
    }

    @include landscape{
      max-width: unset;

      > span:before {
        transform: translate(-50%, -2%) !important;
      }
    }

    @include phone {
      max-width: 335px;

      > span:before {
        width: 205px;
        background-size: 205px;
      }
    }
  }

  &Barrels,
  &Coin {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  &Barrels {
    bottom: 0;

    @include ipad {
      width: 1048px;
      object-fit: contain;
      z-index: -2;
    }

    @media (max-width: 1126px) {
      width: 980px;
    }

    @media (max-width: 1038px) {
      width: 1220px;
      transform: translate(-50%, 0);
      left: 50%;
      right: 50%;
    }

    @include landscape {
      width: 1022px;
    }

    @include phone {
      width: 530px;
      height: 196px;
    }
  }

  &Coin {
    z-index: 2;
    bottom: 80px;

    @media (max-height: 837px) {
      z-index: -1;
    }

    @include landscape {
      left: 0;
    }

    @include phone {
      width: 185px;
      height: 188px;
      object-fit: cover;
      bottom: 40px;
      left: 10vw;
    }
  }

  &Btn {
    margin: auto;
  }

  &InfoContainer {
    @include landscape {
      display: flex;
      gap: 12px;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 32px;
    }
  }

  &InfoWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 24px;
    border: 1px solid #D7DBE0;
    background: $color-white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 24px 32px;
    bottom: 246px;
    z-index: 3;

    @include landscape {
      padding: 16px 20px;
      width: 166px;
      position: unset;
    }

    &Left {
      left: 80px;

      @include ipad {
        left: 40px;
      }

      @include tablet {
        left: 32px;
      }

      @include landscape {
        bottom: unset;
        top: 100px;
        left: 212px;
      }
    }

    &Right {
      right: 80px;
      bottom: 126px;

      @include ipad {
        right: 40px;
      }

      @include tablet {
        right: 32px;
      }

      @include landscape {
        bottom: unset;
        top: 100px;
        left: 53%;
        right: unset;
      }
    }
  }

  &InfoTitle {
    margin-bottom: 8px;
    color: $color-medium-grey-purple;
    font-size: $fs-16;
    font-weight: 500;
    line-height: $lh-24;
    letter-spacing: -0.32px;

    @include landscape {
      margin-bottom: 0;
    }
  }

  &InfoAmount {
    background: var(--gradient, linear-gradient(261deg, #4CB2F1 13.17%, #EA968B 91.6%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $fs-60;
    font-weight: 900;
    line-height: $lh-72;
    letter-spacing: -1.2px;

    @include landscape {
      font-size: $fs-30;
      line-height: $lh-36;
    }
  }

  &InfoType {
    color: #272931;
    font-size: $fs-16;
    font-weight: 700;
    line-height: $lh-19;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    > span {
      display: inline-block;
      margin-left: 8px;
      color: #D0D4E3;
    }
  }
}
