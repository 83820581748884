@import '../../assets/variables';

.Header {
  display: grid;
  grid-template-columns: 96px 1fr 205px;
  align-items: center;
  background-color: $color-white;
  padding: 12px 12px 12px 32px;
  border-radius: 16px;
  border: 1px solid #D7DBE0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

  @media (max-width: 846px) {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  &Container {
    position: fixed;
    top: 16px;
    z-index: 999;
    width: 100%;

    &Mobile {
      @media (max-width: 846px) {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background-color: $color-white;
        top: 0;
        padding-top: 16px;
      }

      .Header {
        box-shadow: none;
        border: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &LogoLink {
    display: flex;
    align-items: center;
  }

  &Btn {
    @media (max-width: 846px) {
      display: none;
    }
  }

  &NavDesktop {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 846px) {
      display: none;
    }
  }

  &MobileBtn {
    width: 92%;
    margin-bottom: 40px;
    margin-left: 16px;
    margin-right: 16px;
  }

  &MobileNav {
    display: none;
    position: relative;

    @media (max-width: 846px) {
      display: block;
    }

    .MobileNav {
      display: none;
    }

    &Active .MobileNav {
      display: flex;
      flex-direction: column;
      background: $color-white;
      position: fixed;
      top: 48px;
      width: 100%;
      transition: max-height 0.3s ease-in-out;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -7px);
      height: calc(100vh - 48px);
      padding-left: 16px;
      padding-right: 16px;

      //@media (max-width: 846px) {
      //  width: calc(100% - 50px);
      //}

      @include phone {
        width: 100%;
      }
    }
  }
}

.MenuNavIcon {
  cursor: pointer;
  transition: 0.2ms;
  position: relative;
  background-color: $color-dark;
  width: 20px;
  height: 1px;

  @media (max-width: 846px) {
    grid-area: mobile-menu;
  }


  &:before,
  &:after {
    transition: transform 0.3s ease;
    content: '';
    position: absolute;
    background-color: $color-dark;
    width: 20px;
    height: 1px;
  }

  &:before {
    top: 5px;
  }

  &:after {
    top: -5px;
  }
}

.MenuNavIconOpen {
  background-color: transparent;

  &:before {
    top: 0;
    transform: rotate(45deg);
  }

  &:after {
    top: 0;
    transform: rotate(-45deg);
  }
}

.mobileMenuIcon {
  position: absolute;
  height: 22px;
  width: 22px;
  z-index: 9999;
  top: -13px;
}
