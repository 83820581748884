@import '../../../assets/variables';

.HowToBuy {
  &Container {
    height: 604px;
    overflow: hidden;
    display: flex;
    padding-top: 105px;
    padding-left: 105px;
    padding-right: 126px;
    border-radius: 40px;
    background:
            radial-gradient(100.42% 98.19% at 95.04% 84.59%, #D0E5FC 0%,
                    rgba(208, 229, 253, 0.00) 100%), #F6F8FA;
    position: relative;

    @media (max-width: 1318px) {
      padding-left: 90px;
      padding-right: 90px;
    }

    @media (max-width: 1183px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (max-width: 1075px) {
      padding-top: 60px;
      padding-left: 60px;
      padding-right: 60px;
      flex-direction: column;
      gap: 60px;
      height: 1143px;
    }

    @include landscape {
      padding-top: 60px;
      padding-left: 60px;
      padding-right: 60px;
      flex-direction: column;
      gap: 60px;
      height: 1173px;
    }

    @include phone {
      padding-top: 40px;
      padding-left: 16px;
      padding-right: 16px;
      height: unset;
      gap: 0;
    }

    &Wrapper {
      @include landscape {
        padding-left: 0;
        padding-right: 0;
      }
    }

    h2 {
      text-align: left;
      margin-bottom: 40px;
      margin-left: 0;

      @include phone {
        font-size: $fs-44;
        font-weight: 400;
        line-height: 110%;
      }
    }

    img {
      @media (max-width: 1075px) {
        margin: auto;
      }

      @include phone {
        width: 331px;
        height: 541px;
      }
    }
  }

  &List {
    padding-left: 51px;
    margin: 0;

    li::before {
      content: counter(li);
      position: absolute;
      left: -51px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      border-radius: 13px;
      background: $color-blue;
      width: 9px;
      padding: 6px 13px;
      font-size: $fs-20;
      font-weight: 800;
      line-height: $lh-24;
      letter-spacing: -0.4px;
    }

    li {
      transition: 0.2ms;
      opacity: 0.4;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 24px;
      list-style: none;
      counter-increment: li;
      max-width: 398px;

      @media (max-width: 1075px) {
        max-width: unset;
      }
    }
  }

  &Img {
    display: none;
  }

  &Title {
    font-size: $fs-28;
    font-weight: 500;
    line-height: $lh-32;
    letter-spacing: -0.56px;
    color: $color-dark;
    cursor: pointer;
  }

  &Description {
    transition: 0.2ms;
    font-size: $fs-20;
    font-weight: 500;
    line-height: $lh-30;
    letter-spacing: -0.6px;
    height: 0;
    visibility: hidden;
  }

  &ProgressLine {
    position: absolute;
    background-color: $color-blue;
    height: 10px;
    width: 25%;
    top: 0;
    left: 0;
    transition: 0.2ms;
  }

  &DescriptionBlock {
    @media (max-width: 1075px) {
      flex: 1;
      min-height: 480px;
    }

    @include landscape {
      min-height: 510px;
    }

    @include phone {
      min-height: unset;
    }
  }
}

.HowToBuyListActive {
  opacity: 1 !important;

  > .HowToBuyDescription {
    height: unset;
    visibility: visible;
  }
}

.HowToBuyImgActive {
  display: block;
}
