@import './breakpoints';

$color-blue: #4B63E3;
$color-purple: #815CEA;
$color-white: #FFFFFF;
$color-dark: #1F2C3F;
$color-medium-grey-purple: #84879A;
$color-text: #637288;
$color-light-grey: #F6F8FD;
$color-grey: #F6F8FA;

$gradient: radial-gradient(72.24% 54.82% at 110.8% 28.19%, #D4D2F9 0%, rgba(215, 213, 252, 0.00) 100%), radial-gradient(71.08% 52.59% at -8.23% 31.06%, #D0E5FC 0%, rgba(208, 229, 253, 0.00) 100%), #F6F8FA;

$fs-80: 5rem;
$fs-70: 4.375rem;
$fs-60: 3.75rem;
$fs-50: 3.125rem;
$fs-44: 2.75rem;
$fs-40: 2.5rem;
$fs-36: 2.25rem;
$fs-32: 2rem;
$fs-30: 1.875rem;
$fs-28: 1.75rem;
$fs-24: 1.5rem;
$fs-20: 1.25rem;
$fs-18: 1.125rem;
$fs-16: 1rem;
$fs-14: 0.875rem;
$fs-12: 0.75rem;
$fs-10: 0.625rem;

$lh-84: 5.25rem;
$lh-77: 4.813rem;
$lh-72: 4.5rem;
$lh-70: 4.375rem;
$lh-66: 4.125rem;
$lh-55: 3.438rem;
$lh-48: 3rem;
$lh-43: 2.688rem;
$lh-36: 2.25rem;
$lh-35: 2.188rem;
$lh-33: 2.063rem;
$lh-32: 2rem;
$lh-30: 1.875rem;
$lh-27: 1.688rem;
$lh-24: 1.5rem;
$lh-19: 1.188rem;
$lh-16: 1rem;
$lh-12: 0.75rem;

//h1 {
//  font-size: $fs-80;
//  line-height: $lh-84;
//}
//
//h2, h3 {
//  font-size: $fs-60;
//  line-height: $lh-70;
//  margin: 0;
//
//  @include phone {
//    font-size: $fs-44;
//    line-height: $lh-55;
//  }
//}

