@import '../../assets/variables';

.Navigation {
  margin-top: 164px;
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;

  &List {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }
}

.ListLink {
  font-family: 'Cakra', sans-serif;
  display: block;
  margin-bottom: 32px;
  text-decoration: none;
  font-size: $fs-40;
  font-weight: 500;
  line-height: $lh-43;
  color: #272931;
  transition: 0.2ms;

  &:hover {
    opacity: 0.7;
  }

  &:focus-visible {
    opacity: 0.6;
  }
}
