@import '../../assets/variables';

.Navigation {
  &List {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.ListLink {
  display: block;
  padding: 10px 16px;
  text-decoration: none;
  font-size: $fs-16;
  font-weight: 500;
  line-height: $lh-24;
  letter-spacing: -0.48px;
  color: $color-text;
  transition: 0.2ms;

  &:hover {
    border-radius: 12px;
    background: $color-light-grey;
  }

  &:focus-visible {
    background: #D7DBE0;
  }
}
