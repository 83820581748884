@import '../../assets/variables';

.faqContainer {
  border-bottom: 1px solid #D7DBE0;
  max-width: 693px;
  margin-bottom: 32px;

  @include tablet {
    max-width: unset;
  }
}

.faqTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding-right: 0;
  padding-left: 0;
  background: none;
  border: none;
  font-size: $fs-24;
  font-style: normal;
  font-weight: 500;
  line-height: $lh-36;
  letter-spacing: -0.72px;
  transition: all 0.2s ease-in-out;
  text-align: left;
  color: $color-dark;
  height: 36px;

  &Active {
    svg {
      transform: rotate(180deg);
    }
  }

  @include phone {
    padding-right: 0;
    padding-left: 0;
  }
}

.faqContent {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  font-size: $fs-20;
  font-style: normal;
  font-weight: 500;
  line-height: $lh-30;
  letter-spacing: -0.6px;
  color: #637288;
  margin-bottom: 32px;

  p {
    margin: 0;
  }

  &Active {
    height: auto;
    visibility: visible;
    opacity: 1;
    padding-top: 16px;

    p {
      margin: 10px;
    }
  }
}

.fagArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 60px;
  border-radius: 47px;
  background: none;
  border: none;
  cursor: pointer;
}
