@import '../../assets/variables';

.button {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 14px 24px;
  border-radius: 42px;
  background: none;
  font-size: $fs-16;
  font-style: normal;
  font-weight: 600;
  line-height: $lh-24;
  letter-spacing: -0.48px;
  cursor: pointer;
  color: $color-white;

  @include phone {
    justify-content: center;
  }
}

.purple {
  transition: 0.2ms;
  background: $color-purple;
  border: none;

  &:hover {
    background-color: $color-blue;
  }

  &:focus {
    background-color: $color-dark;
  }
}

.blue {
  transition: 0.2ms;
  background: $color-blue;
  border: none;

  &:hover {
    background-color: $color-purple;
  }

  &:focus {
    background-color: $color-dark;
  }
}

.outlined {
  transition: 0.2ms;
  border-radius: 32px;
  border: 2px solid #DFE7EF;
  flex-direction: column;
  color: #637288;
  font-size: $fs-14;
  font-weight: 500;
  line-height: $lh-16;
  letter-spacing: -0.28px;
  padding: 22px;
  height: 98px;
  width: 98px;

  &:hover {
    background-color: #DFE7EF;
  }

  &:focus {
    background-color: $color-dark;
  }
}

.size-big {
  border-radius: 43px;
  padding: 18px 20px;
}
