@import '../../../assets/variables';

.BenefitSection {
  &Container {
    scroll-snap-align: center;
    overflow: auto;
    position: relative;
    padding-top: 17vh;

    @include laptop {
      min-height: 914px;
      max-height: 914px;
    }

    canvas {
      min-height: 914px;
      max-height: 914px;
    }

    @include tablet {
      padding-top: 60px;
      padding-bottom: 12px;
      min-height: unset;
      max-height: unset;
    }
  }

  &Scroll {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
    }

    @include phone {
      flex-direction: row;
    }
  }
}

.BenefitBlock {
  display: flex;
  flex-direction: column;
  gap: 96px;

  @include tablet {
    gap: 48px;
    margin-bottom: 48px;
  }

  @include phone {
    flex-direction: row;
    gap: 8px;
  }
}

.BenefitRightBlock {
  justify-content: space-between;

  @include tablet {
    gap: 48px;
  }

  @include phone {
    flex-direction: row;
    gap: 8px;
  }
}

.BenefitTitle {
  font-family: 'Cakra', sans-serif;
  font-size: $fs-30;
  font-style: normal;
  font-weight: 400;
  line-height: $lh-33;
  color: $color-dark;
  max-width: 290px;
  margin-bottom: 16px;

  > span {
    color: $color-blue;
  }

  @include tablet {
    max-width: unset;
  }

  @include phone {
    max-width: 280px;
  }
}

.BenefitDescription {
  color: $color-text;
  font-size: $fs-16;
  font-style: normal;
  font-weight: 500;
  line-height: $lh-24;
  letter-spacing: -0.48px;
}

.BenefitWrapper {
  position: relative;

  @include tablet {
    padding-left: 113px;

    br {
      display: none;
    }

    &1 > .BenefitDescription {
      display: block;
      max-width: 300px;
    }

    &2 > .BenefitDescription {
      display: block;
      max-width: 400px;
    }

    &3 > .BenefitDescription {
      display: block;
      max-width: 450px;
    }

    &5 > .BenefitDescription,
    &4 > .BenefitDescription {
      display: block;
      max-width: 500px;
    }
  }

  @include phone {
    width: 343px;
    padding-left: 0;
    padding-top: 112px;
  }

  &1:before {
    width: 168px;
    height: 52px;
    background: url("../../../assets/images/benefit_line_1.svg") no-repeat;
    top: 24px;
    right: -187px;

    @include tablet {
      background: url("../../../assets/images/card.svg") no-repeat $color-grey;
    }
  }

  &2:before {
    width: 138px;
    height: 20px;
    background: url("../../../assets/images/benefit_line_2.svg") no-repeat;
    top: 26px;
    right: -106px;

    @include tablet {
      background: url("../../../assets/images/file_icon.svg") no-repeat $color-grey;
    }
  }

  &3 {
    > .BenefitTitle {
      max-width: 140px;

      @include tablet {
        max-width: unset;
      }
    }
    &:before {
      width: 169px;
      height: 51px;
      background: url("../../../assets/images/benefit_line_3.svg") no-repeat;
      top: -12px;
      right: -171px;

      @include tablet {
        background: url("../../../assets/images/chart.svg") no-repeat $color-grey;
      }
    }
  }

  &4:before {
    width: 168px;
    height: 52px;
    background: url("../../../assets/images/benefit_line_4.svg") no-repeat;
    top: 26px;
    left: -174px;

    @include tablet {
      background: url("../../../assets/images/secure.svg") no-repeat $color-grey;
    }
  }

  &5:before {
    width: 83px;
    height: 44px;
    background: url("../../../assets/images/benefit_line_5.svg") no-repeat;
    top: -8px;
    left: -96px;

    @include tablet {
      background: url("../../../assets/images/plane.svg") no-repeat $color-grey;
    }
  }

  &1:before,
  &2:before,
  &3:before,
  &4:before,
  &5:before {
    content: '';
    position: absolute;

    @include tablet {
      width: 88px;
      height: 88px;
      left: 0;
      top: 0;
      border-radius: 24px;
      background-position: center center;
    }
  }
}

.BenefitCanvas {
  display: block;
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;

  @include laptop {
    min-height: 914px;
    max-height: 914px;

    canvas {
      min-height: 914px;
      max-height: 914px;
    }
  }

  @include tablet {
    display: none !important;
  }
}
