@import '../../assets/variables';

.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom:  40px;

  &LogoLink {
    display: flex;
    align-items: center;
    grid-area: logo;
  }

  &Navigation {
    grid-area: navigation;

    @media (max-width: 597px) {
      ul {
        flex-direction: column;

        a {
          padding-left: 0;
          border-radius: 0 !important;
        }
      }
    }
  }

  &Year {
    grid-area: year;
  }

  @media (max-width: 597px) {
    display: grid;
    gap: 24px;
    grid-template-areas:
      'logo year'
      'navigation navigation';
  }
}
