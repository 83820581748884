@import '../../assets/variables';

.Container {
  //height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Title {
  color: $color-dark;
  text-align: center;
  font-family: 'Cakra', sans-serif;
  font-size: $fs-70;
  font-weight: 400;
  line-height: $lh-77;
  margin-top: 0;
  margin-bottom: 0;

  @include landscape {
    font-size: $fs-60;
    font-weight: 400;
    line-height: $lh-66;
  }

  @include phone {
    font-size: $fs-44;
    font-weight: 400;
    line-height: $lh-48;
  }

  > span {
    position: relative;
    color: $color-blue;
  }

  &Underline {
    &:before {
      content: '';
      position: absolute;
      background: url("../../assets/images/blue_line.svg");
      background-repeat: no-repeat;
      width: 272px;
      height: 24px;
      bottom: -14px;
      right: 10px;

      @media (max-width: 792px) {
        left: 50%;
        transform: translate(-50%, -2%);
      }

      @include phone {
        transform: translate(-50%, 15%);
      }
    }
  }
}
