@import './variables';
@import './fonts/fonts';

* {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

body {
  font-size: 16px;
  color: $color-text;
  margin: 0;
  font-family: 'Inter Tight', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: calc(1440px - 2 * 80px);
  margin: 0 auto;
  //padding: 0 80px;

  @include ipad {
    max-width: calc(1440px - 2 * 40px);
    padding-right: 40px;
    padding-left: 40px;
  }

  @include tablet {
    max-width: calc(1440px - 2 * 32px);
    padding-right: 32px;
    padding-left: 32px;
  }

  @include landscape {
    max-width: calc(1440px - 2 * 24px);
    padding-right: 24px;
    padding-left: 24px;
  }

  @include phone {
    max-width: calc(1440px - 2 * 16px);
    padding-right: 16px;
    padding-left: 16px;
  }
}

.App {
  position: relative;
  height: 100vh;
}

.menu-open {
  overflow: hidden;
}

.not-found-page {
  background: linear-gradient(287deg, #D4DBE5 24.87%, #E1E6F0 83.93%);

  footer {
    display: none;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.progress-line {
  width: 0;
  height: 2px;
  background-color: #000;
}

//.urals1 {
//  top: 55% !important;
//  left: 45% !important;
//}
//
//.urals2 {
//
//}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

canvas {
  height: 100vh;
}
