@import '../../../assets/variables';

.InvestmentSection {
  &Container {
    padding-top: 80px;
    padding-bottom: 80px;

    @include phone {
      padding-top: 40px;
      padding-bottom: 60px;
    }

    h2 {
      margin-bottom: 80px;

      @include ipad {
        font-size: $fs-80;
        margin-bottom: 60px;
      }

      @include landscape {
        font-size: $fs-70;
        margin-bottom: 60px;
      }

      @include phone {
        font-size: $fs-44;
        margin-bottom: 40px;

        span {
          display: block;
        }
      }
    }
  }

  &DescWrrapper {
    display: grid;
    gap: 4px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    height: 100%;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  &DescRight,
  &DescLeft {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding-top: 44px;
    padding-left: 44px;
    padding-right: 44px;
    border-radius: 40px;
    overflow: hidden;

    @include ipad {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include tablet {
      height: unset;
    }

    @include landscape {
      max-height: 473px;
    }

    @include phone {
      padding-left: 0;
      padding-right: 0;
    }

    > div {
      flex: 1;
    }

    h3 {
      font-family: 'Cakra', sans-serif;
      color: $color-dark;
      font-size: $fs-44;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      margin-top: 0;
      margin-bottom: 16px;

      @include ipad {
        font-size: $fs-40;
      }

      @include phone {
        font-size: $fs-32;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    p {
      font-size: $fs-20;
      font-weight: 500;
      line-height: $lh-30;
      letter-spacing: -0.6px;
      margin: 0;

      @include ipad {
        font-size: $fs-18;

        br {
          display: none;
        }
      }

      @include tablet {
        br {
          display: block;
        }
      }

      @include landscape {
        br {
          display: none;
        }
      }

      @include phone {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  &DescLeft {
    background: radial-gradient(60.67% 74.21% at 37.46% 0%, #D0E5FC 0%, rgba(208, 229, 253, 0.00) 100%), #F6F8FA;

    h3 > span {
      color: $color-blue;
    }

    p {
      margin-bottom: 24px;
    }

    @include tablet {
      img {
        width: 678px;
        height: auto;
        margin: auto;
      }
    }

    @include landscape {
      img {
        max-width: 100%;
      }
    }
  }

  &DescRight {
    background: radial-gradient(43.01% 95.52% at 93.89% 4.78%, #D4D2F9 0%, rgba(215, 213, 252, 0.00) 100%), #F6F8FA;

    h3 > span {
      color: $color-purple;
    }

    @include tablet {
      img {
        width: 646px;
        height: auto;
        margin: auto;
      }
    }

    @include landscape {
      img {
        max-width: 100%;
      }
    }
  }

  &Btn {
    margin-top: 16px;
    margin-bottom: 16px;

    @include phone {
      margin-left: 30px;
      margin-right: 30px;
      width: -webkit-fill-available !important;
    }
  }
}
