@import '../../../assets/variables';

.AdvantagesSection {
  &Wrapper {
    @include phone {
      padding: 0;
    }
  }

  &Container {
    border-radius: 40px;
    background: $color-grey;
    padding: 70px 204px;
    margin-bottom: 80px;
    overflow: hidden;

    @include tablet {
      padding: 80px 100px;
    }

    @include landscape {
      padding: 98px 70px;
    }

    @include phone {
      padding: 130px 16px 117px 16px;
    }

    h2 {
      max-width: unset;
      position: relative;

      > span {
        color: $color-blue;
      }

      @include tablet {
        font-size: $fs-60;
      }

      @include landscape {
        font-size: $fs-44;
      }

      @include phone {
        font-size: $fs-36;
      }

      &:before {
        left: -204px;
        top: -28px;
        background: url("../../../assets/images/arrows.png");

        @include ipad {
          left: -204px;
        }

        @include tablet {
          top: -96px;
          left: -114px;
        }

        @include landscape {
          width: 105px !important;
          height: 100px !important;
          background-size: 105px 100px !important;
          top: -100px;
          left: -85px;
        }

        @include phone {
          top: -122px;
          left: -39px;
        }
      }

      &:after {
        right: -204px;
        top: -7px;
        background: url("../../../assets/images/diogram.png");

        @include ipad {
          right: -204px;
        }

        @include tablet {
          top: -48px;
          right: -175px;
        }

        @include landscape {
          width: 102px;
          height: 98px;
          top: -71px;
          right: -122px;
          background-size: 102px 98px !important;
        }

        @include phone {
          top: 322%;
          left: 61%;
          right: unset;
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 146px;
        height: 138px;
        background-repeat: no-repeat;
        background-size: 146px 138px;
      }
    }

    p {
      font-size: $fs-24;
      font-weight: 500;
      line-height: $lh-36;
      letter-spacing: -0.72px;
      max-width: 582px;
      text-align: center;
      margin: 24px auto;
      position: relative;

      @include phone {
        font-size: $fs-18;
        line-height: $lh-24;
      }

      &:before {
        left: -296px;
        top: 52px;
        width: 136px;
        height: 140px;
        background-size: 136px 140px;
        background: url("../../../assets/images/message.png");

        @media (max-width: 1365px) {
          left: -209px;
        }

        @include ipad {
          left: -204px;
        }

        @include tablet {
          top: 73px;
          left: -118px;
        }

        @include landscape {
          width: 109px;
          height: 112px;
          background-size: 109px 112px;
          top: 126px;
          left: -36px;
        }

        @media (max-width: 658px) {
          left: -55px;
        }

        @media (max-width: 600px) {
          left: -80px;
        }

        @media (max-width: 566px) {
          top: -243px !important;
        }

        @include phone {
          right: -21px;
          top: -287px !important;
          left: unset;
        }
      }

      &:after {
        right: -231px;
        top: 40px;
        width: 139px;
        height: 142px;
        background-size: 139px 142px;
        background: url("../../../assets/images/file.png");

        @include ipad {
          right: -204px;
        }

        @media (max-width: 1365px) {
          right: -194px;
        }

        @include tablet {
          top: 65px;
          right: -121px;
        }

        @include landscape {
          width: 115px;
          height: 118px;
          background-size: 115px 118px;
          top: 107px;
          right: -92px;
        }

        @include phone {
          top: 213px !important;
          left: unset !important;
          right: 62% !important;
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
      }
    }
  }

  &LinkList {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
}
