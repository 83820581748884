@import '../../../assets/variables';

.FAQSection {
  &Container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 80px;

    @include tablet {
      flex-direction: column;
      gap: 64px;
    }

    @include phone {
      gap: 40px;
    }

    h2 {
      max-width: 425px;
      text-align: left;
      margin: 0;

      @include tablet {
        max-width: unset;
      }

      @include phone {
        font-size: $fs-44;
        line-height: $lh-43;
      }
    }
  }
}

.FAQItem {
  border-bottom: none !important;
  margin-bottom: 0;
}
