@import '../../../assets/variables';

.CryptoOil {
  &Container {
    position: relative;
    padding: 109px 156px;
    border-radius: 40px;
    background:
      radial-gradient(70.35% 95.79% at 29.92% -25.67%, #D4D2F9 0%,
              rgba(215, 213, 252, 0.00) 100%),
      radial-gradient(57.27% 104.33% at 70.01% 139.33%, #D0E5FC 22.11%,
              rgba(208, 229, 253, 0.00) 100%), #F6F8FA;

    @include ipad {
      padding: 59px 134px 79px 134px;
    }

    @include tablet {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    @media (max-width: 850px) {
      padding-left: 90px;
      padding-right: 90px;
    }

    @include landscape {
      padding: 52px 16px 135px 16px;
    }

    @include phone {
      padding: 24px;
    }

    &:before {
      left: -44px;
      top: 53px;
      background: url("../../../assets/images/urals_crypto_oil_1.png");

      @include ipad {
        left: -28px;
      }

      @include landscape {
        top: 40px;
        left: -14px;
      }
    }

    &:after {
      right: -44px;
      bottom: 53px;
      background: url("../../../assets/images/urals_crypto_oil_2.png");

      @include ipad {
        right: -28px;
      }

      @include landscape {
        bottom: 30px;
        right: -14px;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 180px;
      height: 184px;
      background-repeat: no-repeat;
      background-size: 180px 184px;
      @include ipad {
        width: 173px;
        height: 176px;
        background-size: 173px 176px;
      }

      @include landscape {
        width: 121px;
        height: 124px;
        background-size: 121px 124px;
      }

      @include phone {
        display: none;
      }
    }

    p {
      font-size: $fs-24;
      font-weight: 500;
      line-height: $lh-36;
      letter-spacing: -0.72px;
      text-align: center;
      max-width: 836px;
      margin: 24px auto 0;

      @include ipad {
        letter-spacing: unset;
        max-width: 790px;
      }

      @include tablet {
        font-size: $fs-20;
        max-width: 600px;
      }

      @include phone {
        text-align: left;
      }
    }

    h2 {
      @include ipad {
        font-size: $fs-80;
        max-width: unset;
      }

      @include tablet {
        font-size: $fs-60;
      }

      @include phone {
        font-size: $fs-40;
        text-align: left;
      }
    }
  }

  &Images {
    display: flex;
    align-items: center;
    margin: 0 auto 24px;
    padding: 22px 30px;
    background-color: $color-white;
    width: max-content;
    border-radius: 37px;
    gap: 32px;

    @include ipad {
      margin-bottom: 48px;
    }

    @include phone {
      margin-bottom: 24px;
      width: -webkit-fill-available;
      justify-content: center;
    }
  }
}
