@font-face {
  font-family: 'Cakra';
  src: url('Cakra-Normal.woff2') format('woff2'),
  url('Cakra-Normal.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Tight';
  src: url('InterTight.woff2') format('woff2'),
  url('InterTight.woff') format('woff');
  font-style: normal;
  font-display: swap;
}


